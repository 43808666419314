import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <span class="created-by">Future Robotics-admin <b></b> </span>
   <div class="socials">
  `,
})
export class FooterComponent {
}

